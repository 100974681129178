import React from 'react';
import { Container, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';

import { styleConfig } from '../../config/config';

import MuiButton from './MuiButton';
import Section from './Common/Section';
import Title from './Common/Title';
import RouteLink from './RouteLink';

const ContentModuleIntroSteps: React.FunctionComponent<ContentModuleIntroStepsProps> = (props) => {
  const { subheading, headline, buttons, content, steps } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    button: {
      marginTop: '4rem',
    },
    content: {
      [theme.breakpoints.down('md')]: {
        marginTop: '4rem',
      },
    },
    steps: {
      textAlign: 'center',
      position: 'relative',
      marginTop: '4rem',
      flexWrap: 'nowrap',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    stepTitle: {
      fontSize: 30,
      marginBottom: 20,
      minHeight: 75,
    },
    step: {
      position: 'relative',
      marginBottom: '2rem',

      [theme.breakpoints.down('md')]: {
        flex: 'none!important',
      },

      '& small': {
        display: 'block',
        fontSize: 16,
        fontFamily: 'Bai Jamjuree',
        fontWeight: 600,
        color: styleConfig.color.text.gold,
      },

      '& h4': {
        display: 'block',
        marginBottom: '2rem',
      },
    },
  }));

  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <Section>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item sm={12} md={12} lg={12} xl={12}>
            {!isNull(headline) && !isNull(subheading) && headline && (
              <Title
                component="h2"
                isSubtitle
                subtitleText={subheading}
                subtitleType="subtitle1"
                titleHeavy
                titleSize="h2"
                titleText={headline}
              />
            )}

            {buttons &&
              buttons.map((block, index) => (
                <div key={`button-group-${index}`} className={classes.button}>
                  {block.button && block.button.url && (
                    <RouteLink href={block.button.url}>
                      <MuiButton
                        aria-label={block.button.title}
                        labelColor={theme.palette.primary.main}
                        name={`button-group-${index}`}
                        height={61}
                        variant={block.button.type === 'block' ? 'contained' : 'outlined'}
                      >
                        <div dangerouslySetInnerHTML={{ __html: block.button.title }} />
                      </MuiButton>
                    </RouteLink>
                  )}
                </div>
              ))}
          </Grid>

          <Grid item sm={12} md={12} lg={12} xl={12}>
            <Grid item className={classes.content}>
              {content && (
                <Typography
                  variant="body1"
                  component="div"
                  key="cm-intro-steps-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
            </Grid>

            {steps && (
              <Grid container spacing={3} justifyContent="center" className={classes.steps}>
                {steps.map((block, index) => (
                  <Grid item key={index} className={classes.step}>
                    <small>0{index + 1}</small>
                    <Typography variant="h3" className={classes.stepTitle}>
                      {block.title}
                    </Typography>
                    <Typography
                      component="div"
                      dangerouslySetInnerHTML={{ __html: block.content }}
                      key={`cm-intro-steps-content-block-${index}`}
                      variant="body1"
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

interface ContentModuleIntroStepsProps {
  buttons: {
    button: {
      title: string;
      url: string;
      buttonUrl: string;
      type: string;
    };
  }[];
  subheading: string;
  headline: string;
  content: string;
  steps: {
    title: string;
    content: string;
  }[];
}

export default ContentModuleIntroSteps;
